<template>
    <div class="row">
        <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box">
                <span class="info-box-icon bg-info elevation-1"><i class="fas fa-sack-dollar"></i></span>

                <div class="info-box-content">
                    <span class="info-box-text">总收入</span>
                    <span class="info-box-number">
                        {{ income }}
                    </span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>
        <!-- /.col -->
        <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3">
                <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-shopping-cart"></i></span>

                <div class="info-box-content">
                    <span class="info-box-text">总订单数</span>
                    <span class="info-box-number">{{ order }}</span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>
        <!-- /.col -->



        <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3">
                <span class="info-box-icon bg-success elevation-1"><i class="fas fa-users"></i></span>

                <div class="info-box-content">
                    <span class="info-box-text">工程师</span>
                    <span class="info-box-number">{{ worker }}</span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>
        <!-- /.col -->
        <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3">
                <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-bell-concierge"></i></span>

                <div class="info-box-content">
                    <span class="info-box-text">服务</span>
                    <span class="info-box-number">{{ service }}</span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>
        <div class="col-12">
            <div class="card text-bg-light mb-3">
                <div class="card-header">趋势</div>
                <div class="card-body">
                    <canvas id="myChartLine"></canvas>
                </div>
            </div>
        </div>
        <!-- /.col -->
    </div>
</template>

<script>
import { getDashData } from '@/requests/api'
import { Chart } from 'chart.js/dist/chart.js'
const colors = [
    "#9ec5fe",
    "#3d8bfd",
    "#e0cffc",
    "#8540f5",
    "#c5b3e6",
    "#8c68cd",
    "#efadce",
    "#de5c9d",
    "#f1aeb5",
    "#e35d6a",
    "#fecba1",
    "#fd9843",
    "#ffe69c",
    "#ffcd39",
    "#a3cfbb",
    "#479f76",
    "#a6e9d5",
    "#4dd4ac",
    "#9eeaf9",
    "#3dd5f3",
    "#e9ecef",
    "#ced4da"
]
export default {
    name: 'Dash',
    data() {
        return {
            income: 0.00,
            worker: 0,
            order: 0,
            service: 0,
        }
    },
    mounted() {
        var that = this
        getDashData().then((res) => {
            that.createLine(this.buildData(res.labels, res.data))
            that.income = res.income.totalIncomes
            that.worker = res.worker.totalWorker
            that.service = res.service.totalService
            that.order = res.order.totalOrder
        })
    },
    methods: {
        buildData(labels, data) {
            var obj = {}
            obj.labels = labels;
            obj.datasets = [];
            data.forEach((item, index) => {
                obj.datasets.push({
                    label: item.label,
                    data: item.data,
                    borderColor: colors[index],
                    lineTension: 0.3
                })
            })
            return obj
        },
        createLine(data) {
            var ctx = document.getElementById("myChartLine")
            var config = {
                type: 'line',
                data: data,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: '趋势'
                        }
                    }
                },
            }
            new Chart(ctx, config)

        },
    }
}
</script>

<style>

</style>